// import { navigate } from '@reach/router'
import React from 'react'
import { connect } from 'react-redux'
import {getTotalListFilters, clearPropertiesAction, setFilterHome} from '../redux/propertiesDucks'
import { useRef } from "react";
import { useState, useEffect } from "react";
import Searcher from "./autocomplete";
import SearcherPro from "./autocompletePro";
// import { useNavigate } from '@reach/router'
import { navigate } from "gatsby"
import toast, { Toaster } from 'react-hot-toast';

const Search = ({totalListFilters,searchHome,dispatch}) => {


    // const navigate = useNavigate();
    const refOperation = useRef();
    const refType = useRef();
    const [locations, setLocation] = useState([]);

    const [errorInput,setErrorInput] = useState(false)
  
    const [router, setRouter] = useState({
      location: "",
      type: "",
      operation: "tipo de operación",
    });
  
    useEffect(() => {
      if (totalListFilters.data) {
        for (let index = 0; index < totalListFilters.data.objects.locations.length; index++) {
          if(locations.length <= 0){
            setLocation((locations) => [
              ...locations,
              totalListFilters.data.objects.locations[index].location_name,
            ]);
          }
        }
      }
    }, [totalListFilters]);

    const updateRoute = (value) => {
        console.log(value.current.value)
      setRouter((router) => ({
        ...router,
        [value.current.name]: value.current.value,
      }));
    };

    useEffect(() => {
        switch(router.operation.toLowerCase()){
            case "venta":
                updateOperation({id:1,name:"Venta"})
                break;
            case 'alquiler':
                updateOperation({id:2,name:"Alquiler"})
                break;
            case 'alquiler temporario':
                updateOperation({id:3,name:"Alquiler Temporario"})
                break;
            default:

        }
    },[router.operation])

    const goRoute = (event) => {
        event.preventDefault();
        if(true){
            dispatch(clearPropertiesAction());
            let routerLink =( searchHome.operation.toLowerCase().replace(/ /g, "-"));
            routerLink += (searchHome.type !== ''
                      ? '/' + searchHome?.type?.toLowerCase().replace(/ /g, "-").normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                      : '' );
            routerLink += (
                  (
                    searchHome.location.length > 0 
                    ?   '/' +  searchHome.location.map((element,index) => (element.name.toLowerCase().replace(/ /g, "-").normalize("NFD").replace(/[\u0300-\u036f]/g, "")))
                    :   ''
                  )
            );
            navigate(routerLink);
        }
        // if(totalListFilters.data.objects.locations.find(element => element.location_name.toLowerCase() === searchHome.location.toLowerCase()) || searchHome.location === '' || searchHome.location === ' '){
        //     // console.log(router.location)
        //     dispatch(clearPropertiesAction());
        //     let routerLink =(
        //           searchHome.operation
        //           .toLowerCase()
        //           .replace(/ /g, "-") +
        //           (searchHome.type ? "/" : '')
        //           + searchHome.type
        //           .toLowerCase()
        //           .normalize("NFD")
        //           .replace(/[\u0300-\u036f]/g, "") 
        //           +
        //           (searchHome.location !== '' ? 
        //             '/' + 
        //             searchHome.location
        //             .toLowerCase()
        //             .replace(/ /g, "-")
        //             .normalize("NFD")
        //             .replace(/[\u0300-\u036f]/g, "")
        //           :'')
        //     );
        //     navigate(routerLink);
        //     console.log(routerLink)
        // }
        // else{
        //     setErrorInput(true);
        //     toast.error("No se encontraron resultados para la ubicación ingresada.",4000) 
        // }
    };

    const updateOperation = (value) => {
        dispatch(setFilterHome({
            ...searchHome,
            operation: value.name
        }));
    }

    const updateTypes = (value) => {
        dispatch(setFilterHome({
            ...searchHome,
            type: value.current.value
        }));
    }

    useEffect(() => {
        dispatch(setFilterHome({...searchHome,type:'',location:[]}))
    },[searchHome.operation])

    useEffect(() => {
        dispatch(setFilterHome({...searchHome,location:[]}))
    },[searchHome.type])


    

    return totalListFilters ? (
        <div class="search-home d-flex align-items-center py-3 py-lg-4">
            <div class="container-fluid">
                <form  onSubmit={goRoute} class="row no-gutters align-items-stretch px-xl-5 search-form mb-0">
                    <div class="col-6 my-2 col-lg-3 px-2 pr-lg-3">
                        <div class="content-select">
                            <select ref={refOperation} onChange={() => updateRoute(refOperation)} name="operation" id="operacion">
                                <option value="tipo de operación">Tipo de operación</option>
                                    {totalListFilters.data && totalListFilters.data.objects.operation_types.find(element => element.operation_type === 1) ? <option value="Venta">Venta</option> : '' }
                                    {totalListFilters.data && totalListFilters.data.objects.operation_types.find(element => element.operation_type === 2) ? <option value="Alquiler">Alquiler</option> : '' }
                                    {totalListFilters.data && totalListFilters.data.objects.operation_types.find(element => element.operation_type === 3) ? <option value="Alquiler Temporario">Alquiler Temporario</option> : ''}
                            </select>
                            <i></i>
                        </div>
                    </div>
                    <div class="col-6 my-2 col-lg-3 px-2 pr-lg-3">
                        <div class="content-select">
                            <select name="type"
                                    id="type"
                                    ref={refType}
                                    onChange={() => updateRoute(refType) + updateTypes(refType)}>
                                <option value="all">Tipo de propiedad</option>
                                    {totalListFilters.data ? 
                                        totalListFilters.data.objects.property_types.map((item,index) => (
                                            <option value={item.type}>{item.type}</option>
                                            )
                                        )
                                        :''
                                    }
                            </select>
                            <i></i>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6">
                        <div class="row no-gutters d-flex align-items-stretch">
                            <div class="col-12 my-2 col-lg-9 px-2 pr-lg-3">
                                <div class={"content-select " + (errorInput ? "error-border" : '')}>
                                    {/* <Searcher lang={locations} updateRoute={updateRoute} /> */}
                                    <SearcherPro updateRoute={updateRoute} />
                                </div>  
                            </div>
                            <div class="col-12 my-2 px-2 px-lg-0 col-lg-3">
                                <button class="btn btn-white">BUSCAR</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div> 
    ):''
}

export default connect(state => ({
    totalListFilters: state.properties.totalListFilters,
    searchHome:state.properties.searchHome
}),null)(Search);
