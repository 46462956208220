import React from 'react'

import { Link} from 'gatsby'

//Helpers
import {getLocation, getName, getFakeAddress, getStatus, is_Sold} from '../helpers/helper.developments'
import {getCover, getQuality, getPriceForMarker} from '../helpers/helper.rendering'
import {getMinAndMaxPrice} from '../helpers/helper.unities'
import { graphql, useStaticQuery } from 'gatsby';
import { getEnvironment, getPrices, getSurface, makeLink } from '../helpers/helper.properties';
import { connect } from 'react-redux'
import { changeSelectedAction } from '../redux/propertiesDucks'

const developmentGroupCard = (props) => {

    const { realEstate } = useStaticQuery(graphql`
      query {
          realEstate {
              name
          }
    }`)
    const {development,developments,dispatch,tag,max} = props
    const inMap = props?.inMap
    const unities = props?.unities

    const getDevelopmentByTag = () => {
        const developmentsSearch = []
        for (const dev of developments) {
            if(dev.custom_tags.find(element => element.name.toLowerCase().replaceAll(" ","-") === tag?.name.toLowerCase().replaceAll(" ","-"))){
                developmentsSearch.push(dev)
            }
        }
        return developmentsSearch.find(element => element.custom_tags.find(atag => atag.name.toLowerCase() === 'portada'))
    }

    console.log(developments)

    return(
        <div class={"card dev-card isGroup "}>
            {getDevelopmentByTag(developments,tag) && <img src={getCover(getDevelopmentByTag(developments,tag)?.photos).original} alt="" />}
            <h2 className="card-title">
                {tag.name}
            </h2>
            <h4 className="card-direction">
                {getDevelopmentByTag(developments,tag) && getDevelopmentByTag(developments,tag)?.fake_address}
            </h4>
            <a target="_blank" href={"/emprendimientos/" + tag.name.replaceAll(" ","-").toLowerCase() } class="btn btn-outline mt-auto"><span class="d-none d-lg-inline">DESCUBRILO</span><span class="icon-plus d-lg-none"></span></a>
        </div> 
    )
}

export default connect(state => ({
    settings: state.settings,
}),null)(developmentGroupCard);