import React from 'react'
import Card from '../development-card'
import { graphql, useStaticQuery } from 'gatsby';

import { connect } from 'react-redux'

import {getCustomTags, getFeatured} from '../../helpers/helper.developments'
import CardDevelopmentGroup from '../cardDevelopmentGroup';

const developments = ({developments,dispatch}) => {
    const { realEstate } = useStaticQuery(graphql`
        query {
            realEstate {
                template_version
                sections {
                  home {
                    developments {
                      subtitle
                      sup_title
                      title
                      button {
                        link
                        value
                      }
                    }
                  }
                }
              }
        }`)

    const {template_version} = realEstate
    const MediaPro = template_version === 'PRO' ? true : false;

    return developments?.data?.objects?.length > 0 && getFeatured(developments?.data?.objects).length > 0 ? (
        <section id="featured-devs">
            {/* <div className="container-fluid pt-lg-5 my-lg-5 ">
                <h1 className="title-2 text-center">{realEstate.sections.home.developments.sup_title}</h1>
            </div> */}
            <div class="container-fluid pt-3 mt-lg-3 pt-lg-3">
                <h2 class="section-title py-5 text-left">{realEstate.sections.home.developments.title}</h2>
            </div>
            <div class="devs mt-4">
                <div class="container-fluid">
                     <div class={"row no-gutters " + ((getCustomTags(developments,"Grupo-emprendimiento").length === 0 || !MediaPro) && 'd-none')} >
                        {
                                getCustomTags(developments,"Grupo-emprendimiento").map((tag,index) => (
                                    // <div className="col-lg-4">
                                    index < 3 &&
                                    <div className={(getCustomTags(developments,"Grupo-emprendimiento").length === 1 
                                        ? 'col-lg-12 ' //Si es uno solo, 100%
                                        : ((getCustomTags(developments,"Grupo-emprendimiento").length < 9 && index === 0 && getCustomTags(developments,"Grupo-emprendimiento").length % 2 === 1)  
                                            ? 'col-lg-12 ' // Si impares menor a 9, el primero 100%
                                            : (getCustomTags(developments,"Grupo-emprendimiento").length < 9) 
                                                ? 'col-lg-6' //Si menores a 9, 50%
                                                :  (getCustomTags(developments,"Grupo-emprendimiento").length >= 9 && getCustomTags(developments,"Grupo-emprendimiento").length % 3 === 1 && index === 0)
                                                    ? 'col-lg-12' // Si es mayor o igual a 9, y sobra 1, 100% el primero
                                                    : (getCustomTags(developments,"Grupo-emprendimiento").length >= 9 && getCustomTags(developments,"Grupo-emprendimiento").length % 3 === 2 && (index === 0 || index === 1)
                                                        ? 'col-lg-6' // Si son 9 o mas, y sobran 2, los primeros 2, 50%  
                                                        : 'col-lg-4' // Si son 9 o mas, 33,3%
                                        )))}>
                                        <CardDevelopmentGroup max={3} developments={developments.data.objects} tag={tag} />
                                    </div>
                                ))
                        }
                    </div>
                    <div class={"row no-gutters " + ((getCustomTags(developments,"Grupo-emprendimiento").length > 0) && 'd-none')}>     
                        {
                        developments.data ?
                        getFeatured(developments.data.objects).map((development,index) => (
                                index < 3 && <div  class={(getFeatured(developments?.data?.objects).length >= 3 ? "col-md-4 " : (getFeatured(developments?.data?.objects).length === 1 ? "col-md-12" : "col-md-6")) + " mb-4 mb-lg-0 p-lg-3"}>
                                    <Card development={development} />
                                </div>                  
                            ))
                            :''
                        }       
                    </div>
                    <div class="row">
                        <div class="col-12 text-center">
                            {/* <h5 class="text-center subtitle_new">{realEstate.sections.home.developments.subtitle}</h5> */}
                            <a href={realEstate.sections.home.developments.button.link} className="mt-5 d-inline-block btn btn-blue">{realEstate.sections.home.developments.button.value}</a>
                        </div>
                    </div>
                </div>
            </div>
        </section> 
    ) : ''
}

export default connect(state => ({
    developments: state.developments.developments,
}),null)(developments);