import { Link } from 'gatsby';
import React, {useEffect, useRef}  from 'react';
import Searcher from '../search'
import { connect } from 'react-redux';
import { getDevelopmentsAction } from '../../redux/developmentsDucks';
import {getTotalListFilters} from '../../redux/propertiesDucks'
import Loading from '../Loading'
import OwlCarousel from 'react-owl-carousel3'

//Helpers
import { getLocation, getLastFeatured } from '../../helpers/helper.developments';
import { getCover } from '../../helpers/helper.rendering';
import { graphql, useStaticQuery } from 'gatsby';
import { getActionByPage, getActionByType, getCodeFromType, getCodes, getSectionFromCode } from '../../helpers/helper.actions';
import { informCustomEvent } from '../../helpers/helper.analytics';

const Home_Main = ({developments,loading,api_key,dispatch,actions}) =>  {

    const slider = useRef()

    const { realEstate } = useStaticQuery(graphql`
      query {
          realEstate {
              slogan
              main_image
          }
    }`)

    // const slider_action = getActionByPage(getActionByType(actions,'Slider de Imagenes Encabezado'),'Inicio')
    // const image_action = getActionByPage(getActionByType(actions,'Imagen Encabezado'),'Inicio')
    // const video_action = getActionByPage(getActionByType(actions,'Video Encabezado'),'Inicio')
    const slider_action = getActionByPage(getActionByType(actions,getCodeFromType('Slider de Imagenes Encabezado')),("INI"))
    const image_action = getActionByPage(getActionByType(actions,getCodeFromType('Imagen Encabezado')),("INI"))
    const video_action = getActionByPage(getActionByType(actions,getCodeFromType('Video Encabezado')),("INI"))

    useEffect(() => {

        if(api_key && !developments?.data?.objects){
            dispatch(getDevelopmentsAction()) // Get all developments
        }
    },[api_key])

    const makeLink = (development) => {
        if(development){
            return "/emprendimientos/" + development.name.replaceAll(" ","-").toLowerCase() + "/" + development.id
        }
    }

    const getSize = () => (screen.width < 992) ? false : true

    const goTo = () => {
        if (video_action[0].url_destiny) {
            window.open(video_action[0].url_destiny + video_action[0].text_utm, '_blank', 'noopener,noreferrer')
            informCustomEvent("PUSH_" + getCodes(video_action[0].type,video_action[0].section) + "_" + (video_action[0].name))
        }
    }

    return !loading ? (
        slider_action.length > 0 || video_action.length > 0 //Caso Actions video/slider
        ?   <>
                {
                    slider_action.length > 0
                    &&  <>
                            <div id="section-galery-home">
                                <div id="dev-secuencial">
                                    <div className="slider" id="dev-gallery">
                                        <OwlCarousel
                                            items={1}
                                            ref={slider}
                                            margin={0}
                                            nav={false}
                                            dots={true}
                                            loop={true}
                                            rewind={true}
                                            autoplay={true}
                                            autoplayTimeout={parseInt(slider_action[0]?.transition_time)*1000}
                                            id="prop-gallery">
                                                { slider_action[0].files.map((file, index)=>(
                                                    <div className="item banner-main banner-ficha emprendimiento" key={index}>
                                                        <img src={file.file} className="object-cover object-center cursor-pointer" alt={realEstate?.name} />
                                                        <div className="info-banner position-absolute">
                                                            <div className="info row align-items-center text-center justify-content-center">
                                                                <div className="col-8 col-lg-12">
                                                                    <a target={'_blank'} onClick={() => informCustomEvent("PUSH_" + getCodes(slider_action[0].type,slider_action[0].section) + "_" + slider_action[0].name)} href={file?.url_destiny + slider_action[0].text_utm} className={"link-gallery " + (!file.url_destiny && 'd-none')}></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )) }
                                        </OwlCarousel>
                                        <div className="arrows-owl">
                                            <div className="arrow" onClick={() => slider.current.prev()}>
                                                <div className="next"><i className="icon-arrow"> </i></div>
                                            </div>
                                            <div className="arrow " onClick={() => slider.current.next()}>
                                                <div className="next"><i className="icon-arrow"> </i></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Searcher />
                            </div>
                        </>
                }
                {
                    video_action.length > 0
                    &&  <>
                            <div id="home-main" className={"banner-main isVideo " + (video_action[0]?.url_destiny === '' ? 'cursor-default' : 'cursor-pointer')} onClick={() => goTo() }>
                                { getSize() &&
                                    <video className={" d-none d-lg-block "} loop autoPlay muted >
                                        <source src={video_action[0].file} type="video/mp4" />
                                    </video> }
                                    <Searcher />
                            </div>
                        </>
                }
            </>
        // Casos sin actiones (Con emprendimientos o sin).
        :   <>
            {
                developments?.data?.objects?.length && !image_action && getLastFeatured(developments.data.objects) ? (
                <div id="home-main" class="banner-main" style={{backgroundImage:'url(' + (developments.data ? getCover(getLastFeatured(developments.data.objects).photos)?.original : realEstate?.main_image) + ")"}} >
                    <div class="item d-flex justify-content-center text-center align-items-center">
                        <div class="opacity"></div>
                        <div class="container" style={{zIndex:"1"}}>
                            <div class="row align-items-center text-center justify-content-center">
                                <div class="col-12 pb-5">
                                    <h1>{developments.data && getLastFeatured(developments.data.objects).name}</h1>
                                    <p>
                                        {developments.data && getLocation(getLastFeatured(developments.data.objects),true)[0]}
                                        <br />
                                        {developments.data && getLocation(getLastFeatured(developments.data.objects),true)[1]}
                                    </p>
                                    <a href={developments.data && makeLink(getLastFeatured(developments.data.objects))} target="_blank" class="btn btn-blue mb-5">DESCUBRILO</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Searcher />
                </div>
                ):
                <div id="home-main" onClick={() =>  informCustomEvent("PUSH_" + getCodes(image_action[0]?.type,image_action[0]?.section) + "_" + image_action[0]?.name) + image_action[0]?.url_destiny ? window.open(image_action[0]?.url_destiny + image_action[0]?.text_utm, '_blank', 'noopener,noreferrer') : ''} class={"banner-main " + (image_action.length === 0 || image_action[0]?.url_destiny === '' ? 'cursor-default' : '') } style={{backgroundImage:'url(' + (image_action.length > 0 ? image_action[0]?.file : realEstate?.main_image) + ")"}} >
                    {image_action.length === 0 && <div class="item d-flex justify-content-center text-center align-items-center">
                        <div class="opacity"></div>
                        <div class="container" style={{zIndex:"1"}}>
                            <div class="row align-items-center text-center justify-content-center">
                                <div class="col-12 pb-5">
                                    <h1>
                                        {realEstate?.slogan}
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>}
                    <Searcher />
                </div>
            }
            </>
    ):
    <Loading absolute={true} />
}

export default connect(state => ({
    developments: state.developments.developments,
    actions:state.actions.actions,
    loading: state.developments.loading,
    api_key: state.settings.keys.tokko
}),null)(Home_Main);